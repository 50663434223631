body{
    -webkit-font-smoothing: antialiased;
    font-family: 'Cabin Condensed', sans-serif !important;
}
.hands{
    width: 1em;
    height: 1em;
    /* padding-bottom: 2.5rem; */
    bottom: .5rem;
    position:relative;
}
.tilt-animation {
    animation-name: tilt;
    animation-duration: .6s;
    
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-delay: .3s;
  }
  
  @keyframes tilt {
    from {
      transform: rotate(-10deg);
    }
    to {
      transform: rotate(10deg);    
    }
  }
  .greeting-text{
    color: #000!important;
    line-height: 1.1;
    display: block;
    font-size: 4.375em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    letter-spacing: 3px;
  }
  .greeting-parag{
    font-size: 1.875rem;
    letter-spacing: 2px;
    color: #868e96;
  }
  .dogGif{
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    }
  .btn{
   margin-bottom: 0rem;
  }
  .contactmeButton{
    
    margin-bottom: 0rem;
   
  }
  @media only screen and (min-width: 1024px) {
    /* CSS styles for desktop screens go here */
    .rowBody{
      margin-top: 8rem!important;
      padding-bottom: 2rem;
    }
    .greeting-text{
      font-size: 4.375rem!important; 
      margin-left: 5rem;
    }
    .greeting-parag{
      font-size: 1.875rem!important; 
      margin-left: 5rem;
    }
    .svgIcons{
      margin-left: 5rem;
    }
    
    .nav-link{ 
      white-space: normal;
    }
    .contactmeButton{
     
      margin-top: 2rem;
      margin-left: 5rem;
    }
    .btn{
     
     font-size:1rem!important;
     }
    .resumeBtn{
     position: relative;
     font-size: 1.5rem!important;
     
    }
    .contactBtn{
    position: relative;
    margin-right: 1rem;
    font-size: 1.5rem!important;
    }

  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* CSS styles for tablet screens go here */
    .rowBody{
      margin-top: 8rem!important;
    }
    .greeting-text{
      font-size: 3.5rem!important; 
    }
    .greeting-parag{
      font-size: 1.5rem!important; 
      
    }
    .svgIcons{
      text-align: center;
    }
    .nav-link{
      
      white-space: normal;
    }
    .contactmeButton{
      margin-top: 3rem;
      
    }
    .resumeBtn{
      float:right;
      margin-right:7rem;
      font-size: 1.5rem!important;
    }
    .contactBtn{
      float:left;
      margin-left: 7rem;
      font-size: 1.5rem!important;
    }
  }
  @media only screen and (max-width: 767px) {
    /* CSS styles for mobile phone screens go here */
    .rowBody{
      margin-top: 8rem!important;
    }
    .greeting-text{
      font-size: 2.375rem!important; 
      color: #000!important;
    }
    .greeting-parag{
      font-size: 1.1rem!important; 
      
    }
    .nav-link{
      text-indent: 10px;
      
    }
    
    .svgIcons{
      text-align: center;
    }
    .contactmeButton{
    
      margin-top: 2rem;
     
    }
    .btn{
    
     font-size:1rem!important;
     }
    .resumeBtn{
     float: right;
     font-size: 1.2rem!important;
    }
    .contactBtn{
    float:left;
    margin-right: .5rem;
    font-size: 1.2rem!important;
    }
   
  }
  .contactDiv{
    margin-top: 9rem!important;
    display: flex;
    justify-content: center;
    
   }
   .projectDiv{
    margin-top: 8rem;
    display: flex;
    justify-content: center;
   }
   .wrapper{
    display: flex;
   }
  .column{
    padding:10px;
  }
  .column1{
    flex:1;
  }
  .column2{
    flex:1;
  }
  .project1Style{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }