.about-section {
  padding: 60px 0;
  background-color: #f9f9f9;
  margin-top: 50px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

h3 {
  font-size: 1.8rem;
  margin-top: 30px;
  color: #555;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

.hexagon-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, auto);
  gap: 20px;
  justify-items: center;
}

.hexagon-container {
  position: relative;
}

.hexagon {
  width: 100px;
  height: 115px;
  background-color: #fff;
  position: relative;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: transform 0.3s;
}

.hexagon:hover {
  transform: scale(2);
}

.hexagon-in1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hexagon-in2 {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hexagon-img {
  width: 90%;
  height: 90%;
}

.hexagon-hover {
  position: absolute;
  top: 50%;
  left: -220px; /* Position to the left of the hexagon */
  transform: translateY(-50%);
  width: 200px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 10;
}

.hexagon-container:hover .hexagon-hover {
  opacity: 1;
  transform: translateY(-50%) translateX(-10px); /* Slightly move to the right on hover */
}

/* Specific placement for each hexagon */
.hexagon-1 {
  grid-column: 2 / span 2;
}

.hexagon-2 {
  grid-column: 1 / span 2;
}

.hexagon-3 {
  grid-column: 3 / span 2;
}

.hexagon-4,
.hexagon-5,
.hexagon-6,
.hexagon-7 {
  grid-column: span 1;
}

.hexagon-8 {
  grid-column: 1 / span 2;
}

.hexagon-9 {
  grid-column: 3 / span 2;
}

.hexagon-10 {
  grid-column: 2 / span 2;
}
